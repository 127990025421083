html {
  font-size: 62.5%;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}
h1 {
  margin-bottom: 0;
  font-size: 3.6rem;
}
p {
  margin-bottom: 0.5em;
  font-size: 1.6rem;
  line-height: 1.6;
}
.button {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 25px;
  border-radius: 4px;
  
}
.button-primary {
  position: relative;
  background-color: #d3b924;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  transition: color 0.3s ease-in;
  z-index: 1;
}
.button-primary:hover {
  color: #c0ca33;
  text-decoration: none;
}
.button-primary::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #0a0808;
  border-radius: 4px;
  opacity: 0;
  -webkit-transform: scaleX(0.8);
  -ms-transform: scaleX(0.8);
  transform: scaleX(0.8);
  transition: all 0.3s ease-in;
  z-index: -1;
}
.button-primary:hover::after {
  opacity: 1;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.ovesrlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #3f3a3a;
  
}

.navbar.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #151515;
}
.d-block {
  display: block;
  height: 550px;
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 70px; /* nilai margin-top harus disesuaikan dengan tinggi navbar */
  margin-bottom: 50px; /* memberikan jarak antara gambar dan konten di bawahnya */
}

/* .carousel-inner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 650px;
} */


h1 {
  font-size: 36px;
  color: white;
  margin-top: 0; /* menghapus margin atas pada h1 agar tidak ada jarak berlebih dengan gambar */
}

/* .navbar.fixed-top {
  position: fixed;
  -webkit-animation: navbar-animation 0.6s;
  animation: navbar-animation 0.6s;
  background-color: #151515;
} */

/* .d-block {
  display: absolute !important;
  height: 650px;
  position: relative;
  width: 100%;
} */

/* .carousel {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  z-index: 1;
} */

/* .carousel-control-prev {
  left: 75px;
}

.carousel-control-next {
  right: 75px;
} */
.header-area {
  position: relative;
  height: 0px !important;
  margin-bottom: 45vw;
  margin-top: 80px;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repear;
  background-size: cover;
}
/* .banner {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  color: #fff;
  text-align: center;
  z-index: 1;
  
} */
/* .banner h1 {
  font-weight: 800;
}
.banner p {
  font-weight: 700;
} */
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  width: 100%;
  height: 80px;
  transition: background 0.6s ease-in;
  z-index: 2;
}
.navbar .navbar-brand {
  font-family: 'Lobster', cursive;
  font-size: 2.5rem;
}
.navbar .navbar-toggler {
  position: relative;
  height: 50px;
  width: 50px;
  border: none;
  cursor: pointer;
  outline: none;
}
.navbar .navbar-toggler .menu-icon-bar {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  transition: all 0.3s ease-in;
}
.navbar .navbar-toggler .menu-icon-bar:first-child {
  opacity: 1;
  -webkit-transform: translateY(-1px) rotate(45deg);
  -ms-sform: translateY(-1px) rotate(45deg);
  transform: translateY(-1px) rotate(45deg);
}
.navbar .navbar-toggler .menu-icon-bar:last-child {
  opacity: 1;
  -webkit-transform: translateY(-1px) rotate(135deg);
  -ms-sform: translateY(-1px) rotate(135deg);
  transform: translateY(-1px) rotate(135deg);
}
.navbar .navbar-toggler.collapsed .menu-icon-bar {
  opacity: 1;
}
.navbar .navbar-toggler.collapsed .menu-icon-bar:first-child {
  -webkit-transform: translateY(-7px) rotate(0);
  -ms-sform: translateY(-7px) rotate(0);
  transform: translateY(-7px) rotate(0);
}
.navbar .navbar-toggler.collapsed .menu-icon-bar:last-child {
  -webkit-transform: translateY(5px) rotate(0);
  -ms-sform: translateY(5px) rotate(0);
  transform: translateY(5px) rotate(0);
}
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  color: #fff;
  font-size: 1.6rem;
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar .dropdown-menu {
  padding: 0;
  background-color: #151515;
}
.navbar .dropdown-menu .dropdown-item {
  position: relative;
  padding: 10px 20px;
  color: #fff;
  font-size: 1.4rem;
  border-bottom: 1px solid #151515;
  transition: color 0.2s ease-in;
}
.navbar .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}
.navbar .dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: #c0ca33;
}
.navbar .dropdown-menu .dropdown-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 5px;
  background-color: #c0ca33;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}
.navbar .dropdown-menu .dropdown-item:hover::before {
  opacity: 1;
}

.navbar.fixed-top.navbar-dark .navbar-nav .nav-link.active {
  color: #c0ca33;
}
.navbar.fixed-top.navbar-dark .navbar-nav .nav-link::after {
  background-color: #c0ca33;
}
.content {
  padding: 0px 0;
}
@media screen and (max-width: 768px) {
  .navbar-brand {
    margin-left: 20px;
  }
  .navbar-nav {
    padding: 0 20px;
    background-color: #151515;
  }
  .navbar.fixed-top .navbar-nav {
    background: transparent;
  }
}
/* @media screen and (min-width: 767px) {
  .banner {
    padding: 0 150px;
  }
  .banner h1 {
    font-size: 5rem;
    background-color: #151515;
  }
  .banner p {
    font-size: 2rem;
    background-color: #151515;
  } */
  .navbar-dark .navbar-nav .nav-link {
    padding: 23px 15px;
  }
  .navbar-dark .navbar-nav .nav-link::after {
    content: '';
    position: absolute;
    bottom: 15px;
    left: 30%;
    right: 30%;
    height: 1px;
    background-color: #fff;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: transform 0.1s ease-in;
  }
  .navbar-dark .navbar-nav .nav-link:hover::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .dropdown-menu {
    min-width: 200px;
    -webkit-animation: dropdown-animation 0.3s;
    animation: dropdown-animation 0.3s;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }

@-webkit-keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}








.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

header {
  width: 100%;
  text-align: center;
}

.content {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}



body {
  /* background: linear-gradient(-45deg, #000000, #b3993b, #060606, #5c6507);
  background-size: 1500% 1700%;
  animation: gradient 5s linear infinite; */
  background-color: #2a240e;
  height: 100vh;
}

/* @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
} */

.row {
  display: flex;
}



.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  width: 100%;
  height: 550px;
}

/* .vleft, .vright {
  position: absolute;
  top: 0;
  height: 100%;
}
.vleft {
  left: 0;
  width: 30%;
}
.vright {
  right: 0;
  width: 30%;
}
@media only screen and (max-width: 768px) {
  .vleft, .vright {
    display: none;
  }
} */

/* @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */




header li {
  text-align: left;
  color: white;

}


.titik {
  text-align: left;
  color: white;
  list-style: disc;
}

li {
  margin: 10px 0;
}

.putih {
  border: none;
  border-top: 5px solid rgb(0, 0, 0);
}
/* header {
  width: 1000px;
  margin: 0 auto;
  text-align: center;
  /* background: linear-gradient(to bottom, #2c5b15, #183013); */
  /* height: auto;
} */ */


.paragraf {
  text-align: left;
}

h2,h3{
    color: white;
  }


p{
  color: white;
  padding: 5px;
  text-align: left;
  margin: 10px 0;
}


.button-wrapper {
  margin-top: 20px;
}

.login {
  width: 200px !important;
  font-weight: bold;
  border-radius: 10px !important;
  padding: 10px 20px;
  border: none;
  background: linear-gradient(to bottom, #767600d4, #e2b500);
  color: #fff;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
}

.dft {
  background-color: #c3b70d !important;
  height: 30px;
  color: #fffdfd;
  font-size: 14px;
width: 70px !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dft:hover {
  background-color: #382b03 !important;
}


button>a{
  font-weight: 200;
}
button:hover {
  background-color: #0a0909;
}
a {
  color: #fbfbfb !important;
  text-decoration: none;
  background-color: transparent;
}




.table-1 {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  position: relative;
  width: 100%;
  text-align: center;
  margin: auto;
  /* box-shadow: 1px 3px 30px #bfa3a3; */
  border: 2px solid #838383;
}

@media (max-width: 768px) {
  .table-1 {
    width: 100%;
    max-width: 100%;
  }
}

/* h1 {
  text-align: center;
  color: rgb(242, 255, 0);
  text-shadow: 0 0 30px rgb(255, 255, 0);
  padding-bottom: 10px;
} */


.table-1 td,
th {
  border: 1px solid #000000;
  text-align: center;
  padding: 8px;
  

}

.table-1 th{
background-color: rgb(0, 0, 0);
color: aliceblue;
font-size: 13px;

}

.table-1 tr:nth-child(even) {
  background-color: rgb(36, 38, 50);
  color: aliceblue;
  font-size: 7px;
}

.table-1 tr:nth-child(odd) {
  background-color: rgb(69 74 96);
  color: aliceblue;
  font-size: 7px;
}

/* a {
  color: aliceblue;
} */


.table-2 {
  width: 100%;
  margin: left;
  border-collapse: collapse;
  border: 1px solid #000000;
}
.table-2 th {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: left;
  height: 30px;
  border: 1px solid #000000;
}

.table-2 tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border: 1px solid #000000;
  text-align: left;
}

.table-2 tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border: 1px solid #000000;
  text-align: left;
}

img {
  width: calc(100vw);
  height:auto;
}
